
#root {
    margin: 0;
    font-family: "proxima-nova", Arial, Helvetica, sans-serif;
    font-size: 1em;
    -webkit-text-size-adjust: 1em; 
    -ms-text-size-adjust: 1em; 
    color: white;
    width: 100vw;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: linear-gradient(180deg,rgba(130, 173, 219, 1) 0%,rgba(115, 174, 201, 1) 60%,rgba(235, 200, 177, 1) 100%);
}

@media screen and (min-width: 900px) {

    #root  {  
      font-size: 1.1em;
      -webkit-text-size-adjust: 1.1em; 
      -ms-text-size-adjust: 1.1em; 
    }
  
  }