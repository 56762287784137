#chiSkyline {
position: absolute;
bottom: 0;
}

.fillColor {
  fill: #4077a8;
}

.filler {
    opacity: 0.27;
}

.backBuildings {
  opacity: 0.5;
}
.airplane {
  fill: #4077a8;
  opacity: 0.87;
  isolation: isolate;
}

.midBuildings {
  opacity: 0.65;
}

.willis {
  opacity: 0.78;
}

.stroke-nofill {
  fill: none;
  stroke: #4077a8;
  stroke-miterlimit: 10;
}